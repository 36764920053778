import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Rooms from "../components/Rooms"
import AOS from 'aos';
import 'aos/dist/aos.css';


class RoomsPage extends React.Component {

    constructor({ pageContext: { localeData }, data }) {
        super()
        this.roomsPage = data.roomsPage
        this.bgImage = data.bgImage.childImageSharp
        this.rooms = data.rooms
        this.localeData = localeData
    }
    
    componentDidMount() {
        AOS.init({
            once: true,
            easing: 'ease-in-out',
            useClassNames: true
        })
    }    

    render() {
        return (
          <Layout localeData={this.localeData} title={this.roomsPage.frontmatter.title}>
              <BookingLine localeData={this.localeData}/>
              <Breadcrumb slug={this.roomsPage.frontmatter.slug} title={this.roomsPage.frontmatter.title} bgImage={this.bgImage} bgImageOverlay={true} />
              <Rooms data={this.roomsPage} rooms={this.rooms.edges} />
          </Layout>
        );    
    }
};
export default withI18next()(RoomsPage);

export const roomsPageQuery = graphql`
  query($id: String!, $locale: String!) {
    roomsPage: markdownRemark(id: { eq: $id }){   
        html
        frontmatter {
            slug
            title
        }
    }
    bgImage: file(relativePath: { eq: "rooms/room_header_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rooms: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "room"}}, fields: {locale: {eq: $locale}}}
    sort: {fields: frontmatter___order, order: ASC}
    ) {      
        edges {
            node {
                frontmatter {
                    slug
                    title
                    description
                    price
                    bed
                    view
                    wifi
                    breakfast
                    max_guest
                    image {
                        childImageSharp {
                            fluid(maxWidth: 770, quality: 90) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
    
}`